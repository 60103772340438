import { MutationTree } from 'vuex';
import { IModuleAppointmentState } from '../types';
import { deepClone } from 'fast-json-patch';
export const mutations: MutationTree<IModuleAppointmentState> = {
    fetchAppointments(state) {
        state.loadingAppointments = true;
    },
    fetchAppointmentsSuccess(state, payload) {
        state.appointments = [];
        state.appointments = deepClone(payload.data.GetAppointmentObjectJsonResult);
        state.loadingAppointments = false;
    },
    fetchProperty(state) {
        state.loadingProperty = true;
    },
    fetchPropertySuccess(state, payload) {
        state.property = null;
        state.property = deepClone(payload.data.GetPropertyJsonResult.Value);
        state.loadingProperty = false;
    },
    fetchProject(state) {
        state.loadingProperty = true
    },
    fetchProjectSuccess(state, payload) {
        state.project = null;
        state.project = deepClone(payload.data.GetProjectJsonResult.Value);
        state.loadingProperty = false;
    },
    syncAppointmentClick(state, payload) {
        state.appointmentClicked = payload.id;
    },
    sendContact(state) {
        state.statusSendForm.loading = true;
    },
    sendContactSuccess(state, payload) {
        state.statusSendForm.loading = false;
        state.statusSendForm.status = payload.message;
    },
    storeConfig(state, payload) {
        Object.keys(payload).forEach((key: string) => {
            if (state.config.hasOwnProperty(key)) {
                if (key === 'key' && payload[key].indexOf('%') !== -1) {
                    state.config[key] = decodeURIComponent(payload[key]);
                } else {
                    state.config[key] = payload[key];
                }
            }
        });
    },
    storeError(state, payload) {
        state.error = payload;
    }
};
