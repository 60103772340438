import config from "./config";
import Axios from "axios";

export const appointmentService = {
  getAppoinmentsByObjectID,
  getCalendarHistories,
  getProperty,
  sendContactAppointment,
  getProject,
};

async function getAppoinmentsByObjectID(criteria: {
  key: string;
  objectId: string;
  IsProject: boolean;
}) {
  const { key, objectId, IsProject } = criteria;
  return Axios({
    method: "POST",
    url: `${config.API_URL}/client/appointment/${objectId}`,
    data: {
      key: key,
      IsProject: IsProject,
    },
  });
}

async function getCalendarHistories(criteria: {
  key: string;
  objectId: string;
}) {
  const { key, objectId } = criteria;
  return Axios({
    method: "GET",
    url: `${config.API_URL}/client/calendar/${objectId}`,
    params: {
      key: key,
    },
  });
}

async function getProperty(criteria: { key: string; objectId: string }) {
  const { key, objectId } = criteria;
  return Axios({
    method: "POST",
    url: `${config.API_URL}/client/property/${objectId}`,
    data: {
      key: key,
    },
  });
}

async function getProject(criteria: { key: string; objectId: string }) {
  const { key, objectId } = criteria;
  return Axios({
    method: "POST",
    url: `${config.API_URL}/client/project/${objectId}`,
    data: {
      key: key,
    },
  });
}

async function sendContactAppointment(criteria: {
  key: string;
  objectId: string;
  data: any;
  email: string;
  noEmail?: boolean;
}) {
  const { data, objectId, key, email, noEmail } = criteria;

  const mailData = {
    From: "noreply@omnicasa.com",
    To: email,
    Bcc: "media@omnicasa.com",
    HtmlBody: data.SendMail.HtmlBody,
    Subject: data.ContactData.Subject,
  };

  const payload = {
    SendMail: mailData,
    ContactData: data.ContactData,
    capchaToken: data.headers.ReCaptchaResponse,
    useCaptcha: data.headers.UseCaptcha,
    noEmail: noEmail,
  };

  return Axios({
    method: "POST",
    url: `${config.API_URL}/client/contact-appointment/${objectId}`,
    data: {
      ...payload,
      key: key,
    },
  });
}
