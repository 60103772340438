import { GetterTree } from 'vuex';
import { appointmentService } from '@/_services';
import { IRootState, IModuleAppointmentState } from '../types';
import { appointment, state } from '.';

export const getters: GetterTree<IModuleAppointmentState, IRootState> = {
    getAppointments: function (state) {
        return state.appointments;
    },
    getAppointmentByID: function (state) {
        return function (id: string) {
            return state.appointments!.find(appointment => appointment.AppointmentID.toString() === id) || null;
        }
    },
    getProperty: function (state) {
        return state.property;
    },
    getProject: function (state) {
        return state.project;
    },
    getStatusLoadingAppointments: function (state) {
        return state.loadingAppointments;
    },
    getStatusLoading: function (state) {
        return state.loadingProperty;
    },
    getAppointmentClicked: function (state) {
        return state.appointmentClicked;
    },
    getStatusSendFormLoading: function (state) {
        return state.statusSendForm.loading;
    },
    getStatusSendForm: function (state) {
        return state.statusSendForm.status;
    },
    getConfig: (state) => {
        return state.config;
    },
    getError: (state) => {
        return state.error;
    }
};
