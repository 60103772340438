import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IRootState, IModuleAppointmentState } from "../types";

export const state: IModuleAppointmentState = {
  statusSendForm: {
    loading: false,
    status: null,
  },
  appointments: [],
  appointmentClicked: 0,
  property: null,
  project: null,
  loadingAppointments: true,
  loadingProperty: true,
  config: {
    key: "",
    objectId: "",
    isProject: "false",
    logo: "",
    language: "nl",
    email: "",
    emailTest: "",
    linkCss: "",
    isMobile: "",
    fontColor: "",
    font: "",
    fontSize: "",
    buttonFontColor: "",
    buttonBackColor: "",
    closeSpan: "",
    loadingImage: "",
    siteUrl: "",
    propertyUrl: "",

    // condition text
    confirmReadDescriptionForSale: "",
    confirmReadDescription: "",
    confirmRequestProof: "",
    confirmEnoughMoney: "",
    confirmMaxPerson: "",
    confirmNeedPayTotal: "",
    confirmNeedPayPrice: "",
    confirmNeedPayChargesRenter: "",
    confirmNeedPayTotalCost: "",
    confirmNeedPayPriceX3: "",

    // condition show
    showConfirmReadDescriptionForSale: "",
    showConfirmReadDescription: "",
    showConfirmRequestProof: "",
    showConfirmEnoughMoney: "",
    showConfirmMaxPerson: "",
    showConfirmNeedPay: "",
    showAllAppointment: "",

    // condition custom
    conditionCustom1: "",
    conditionCustom2: "",

    showWhenFull: "no",
    showWhenFullTitle: "",
    showWhenFullDescription: "",
    sendConfirmation: "yes",
    showWhenNoAppointments: "no",
  },
  error: {
    message: "",
    times: 0,
  },
};

const namespaced: boolean = true;

export const appointment: Module<IModuleAppointmentState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export const APPOINTMENT_NAMESPACE = "appointment";
