

































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-cal/dist/vuecal.css";
import "@/assets/scss/omnicasa.scss";
import { sendMessage, bindEvent, removeEvent, stringValidJson } from "./_utils";
import moment from "moment";
import { namespace } from "vuex-class";
import { APPOINTMENT_NAMESPACE } from "./_store/appointment";
import { IConfigAppointmentModule } from "./_store/types";
import en from "vee-validate/dist/locale/en.json";
import nl from "vee-validate/dist/locale/nl.json";
import fr from "vee-validate/dist/locale/fr.json";
import { localize } from "vee-validate";

const appointmentModule = namespace(APPOINTMENT_NAMESPACE);

@Component({
  components: {}
})
export default class App extends Vue {
  @appointmentModule.Action storeConfig!: (
    payload: IConfigAppointmentModule
  ) => void;
  @appointmentModule.Getter getConfig!: IConfigAppointmentModule;

  get config() {
    return this.getConfig;
  }

  get buttonBackColor() {
    return "#" + this.getConfig.buttonBackColor || "";
  }

  get buttonFontColor() {
    return "#" + this.getConfig.buttonFontColor || "";
  }

  get showBackButton() {
    if (
      this.$route.name == "contact-appointment" ||
      this.$route.name == "contact-appointment-project"
    ) {
      return true;
    }
    return false;
  }

  get styleApp() {
    let style = "";
    if (this.getConfig.fontColor) {
      style += `color: ${this.getConfig.fontColor};`;
    }
    if (this.getConfig.font) {
      style += `font-family: ${this.getConfig.font};`;
    }
    if (this.getConfig.fontSize) {
      style += `font-size: ${this.getConfig.fontSize};`;
    }
    return style;
  }

  handleRouterBack() {
    switch (this.$route.name) {
      case "open-appointment":
        break;
      case "contact-appointment-project":
        this.$router.replace({ name: "open-appointment" });
        break;
      case "contact-appointment":
        this.$router.replace({ name: "open-appointment" });
        break;
      default:
        this.$router.back();
        break;
    }
  }

  handleCloseModal() {
    sendMessage({ type: "hide-modal", mess: "hide-modal" });
  }

  mounted() {
    const vm = this as any;

    bindEvent(window, "message", this.windowEventHandler);

    setTimeout(() => {
      vm.handleCss();
    }, 1000);
  }

  windowEventHandler(e: any) {
    const vm = this as any;
    if (e.data && typeof e.data === "string" && stringValidJson(e.data)) {
      const data = JSON.parse(e.data);
      if (data.type === "omnicasa-route") {
        vm.$router.push({
          name: data.message
        });
      }

      if (data.type === "omnicasa" && data.message) {
        vm.storeConfig(data.message);
      }
    }
  }

  beforeDestroy() {
    removeEvent(window, "message", this.windowEventHandler);
  }

  setVeeValidateLanguage(language: string) {
    switch (language) {
      case "nl":
        localize("nl", nl);
        break;
      case "fr":
        localize("fr", fr);
        break;
      case "en":
        localize("en", en);
        break;
      default:
        localize("en", en);
        break;
    }
  }

  @Watch("config", { deep: true })
  handleChangeConfig() {
    if (
      this.config.key !== "" &&
      this.config.key !== undefined &&
      this.config.key !== null
    ) {
      this.handleCss();
      this.setVeeValidateLanguage(this.config.language);
      if (this.config.language) {
        this.$i18n.locale = this.config.language;
      } else {
        this.$i18n.locale = "nl";
      }
      this.$router.replace({
        name: "open-appointment"
      });
    }
  }

  handleCss() {
    const css = `#app {
          ${
            this.getConfig.fontSize
              ? "font-size: " + this.getConfig.fontSize + ";"
              : ""
          }
          ${
            this.getConfig.font
              ? "font-family: " + this.getConfig.font + ";"
              : ""
          }
          ${
            this.getConfig.font
              ? "color: #" + this.getConfig.fontColor + ";"
              : ""
          }
        }
        .btn-custom {
          ${
            this.getConfig.buttonFontColor
              ? "color: #" + this.getConfig.buttonFontColor + "!important;"
              : ""
          }
          ${
            this.getConfig.buttonBackColor
              ? "border-color: #" +
                this.getConfig.buttonBackColor +
                "!important;"
              : ""
          }
          ${
            this.getConfig.buttonBackColor
              ? "background-color: #" +
                this.getConfig.buttonBackColor +
                "!important;"
              : ""
          }
        }
         .appointment-btn {
          ${
            this.getConfig.buttonBackColor
              ? "color: #" + this.getConfig.buttonBackColor + "!important;"
              : ""
          }
          ${
            this.getConfig.buttonBackColor
              ? "border-color: #" +
                this.getConfig.buttonBackColor +
                "!important;"
              : ""
          }
        }
        .custom-control-input:checked ~ .custom-control-label::before {
            color: #fff;
            ${
              this.getConfig.buttonBackColor
                ? "border-color: #" +
                  this.getConfig.buttonBackColor +
                  "!important;"
                : ""
            }
            ${
              this.getConfig.buttonBackColor
                ? "background-color: #" +
                  this.getConfig.buttonBackColor +
                  "!important;"
                : ""
            }
        }
        .vuecal__cell--has-events .vuecal__cell-content {
            ${
              this.getConfig.buttonBackColor
                ? "border-color: #" +
                  this.getConfig.buttonBackColor +
                  "!important;"
                : ""
            }
        }
        .vuecal__cell-events-count {
            ${
              this.getConfig.buttonBackColor
                ? "background: #" +
                  this.getConfig.buttonBackColor +
                  "!important;"
                : ""
            }
        }
        .warning {
          ${
            this.getConfig.buttonBackColor
              ? "background-color: #" +
                this.getConfig.buttonBackColor +
                "!important;"
              : "background-color: yellow !important;"
          }
          ${
            this.getConfig.buttonFontColor
              ? "color: #" + this.getConfig.buttonFontColor + "!important;"
              : "color: #fff !important;"
          }
          opacity: 0.5;
        }
      `;
    const cssCustom = document.createElement("style");
    cssCustom.innerText = css.replace(/\r?\n|\r/g, "");
    cssCustom.type = "text/css";
    window.document.head.appendChild(cssCustom);
  }
}
