import { IProperty } from '@/model/property.model';
import { IProject } from '@/model/project.model';

// Send a message to the parent
export function sendMessage(msg: { type: string, mess: string }) {
    setTimeout(() => {
        // Make sure you are sending a string, and to stringify JSON
        window.parent.postMessage(msg, '*');
    }, 100);
}

export function roundToFixed(num: any) {
    return Math.round(num * 100) / 100;
}

export function wrapToHtml(text: string, element?: 'div' | 'span') {
    const el = document.createElement(element ? element : 'span');
    el.innerHTML = text;
    return el;
}

export function getHeightDocument() {
    const body = document.body;
    const html = document.documentElement;

    const height = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);
    return height + 20;
}

// addEventListener support for IE8
export function bindEvent(element: any, eventName: any, eventHandler: any) {
    if (element.addEventListener) {
        element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, eventHandler);
    }
}

// removeEventListener support for IE8
export function removeEvent(element: any, eventName: string, eventHandler: any) {
    if (element.removeEventListener) {
        element.removeEventListener(eventName, eventHandler);
    } else if (element.attachEvent) {
        element.detachEvent('on' + eventName, eventHandler);
    }
}

export function stringValidJson(str: string): boolean {
    if (/^[\],:{}\s]*$/.test(str.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
        //the json is ok
        return true;
    } else {
        //the json is not ok
        return false;
    }
}

export function getRegionType(postCode: number): 'Brussels' | 'Wallonia' | 'Flanders' | '' {
    if (postCode >= 1000 && postCode <= 1299) {
        return 'Brussels';
    } else if ((postCode >= 1300 && postCode <= 1499) || (postCode >= 4000 && postCode <= 7999)) {
        return 'Wallonia';
    } else if ((postCode >= 1500 && postCode <= 3999) || (postCode >= 8000 && postCode <= 9999)) {
        return 'Flanders';
    }
    return '';
}

export function getAddressProperty(prop: IProperty, $glue?: string) {
    let address: string = '';
    if (prop) {

        if (prop.PublishAddress === true) {
            address += prop.Street + ' ' + prop.HouseNumber;
        }

        if (address !== '') {
            if ($glue) {
                address += $glue;
            }
            else {
                address += ', ';
            }
        }
        address += prop.Zip + ' ' + getCityAndCountry(prop);
    }
    return address;
}

export function getAddressProject(prop: IProject) {
    let address: string = '';
    if (prop) {
        address += prop.Zip + ' ' + prop.City;
    }
    return address;
}

export function getCityAndCountry(prop: IProperty): string {
    let country = '';
    if (prop.CountryID && prop.CountryID !== 10) {
        if (prop.CountryName) {
            country = ` (${prop.CountryName})`;
        }
    }
    return prop.City + country;
}

export function getBoolean(value: any) {
    switch (value) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
            return true;
        default:
            return false;
    }
}

/**
 * Delay after milisecond. 
 * 
 * @export
 * @param {number} timeout
 * @returns {Promise<any>}
 */
export function delay(timeout: number): Promise<any> {
    return new Promise<any>(resolve => {
        setTimeout(resolve, timeout);
    });
}

/**
 * Retry with number & interval
 * 
 * @export
 * @template T 
 * @param {number} number 
 * @param {(number | number[])} interval 
 * @param {(Promise<T> | (() => Promise<T>))} action 
 * @returns {(Promise<T | undefined>)} 
 */
export async function retry<T>(number: number, interval: number | number[], action: (() => Promise<T>), again?: (error: Error) => boolean): Promise<T> {
    for (let tried = 0; tried < number; ++tried) {
        try {
            return await action();
        } catch (error) {
            if (!!again && !again(error)) {
                throw error;
            }
            if (tried === (number - 1)) {
                throw error;
            }
            if (interval instanceof Array) {
                if (tried < interval.length) {
                    await delay(interval[tried]);
                }
            } else {
                await delay(interval);
            }
        }
    }
    throw new RangeError('Invalied retry number.');
}

function getTargetName(target: any): string {
    const typeOfTarget = typeof (target);
    if (typeOfTarget === 'function') { // static method.
        return target.name;
    }
    if (typeOfTarget === 'object') { // method.
        return target.constructor.name;
    }
    return '??';
}

export const asyncLoading = (target: any, methodName: string, descriptor: TypedPropertyDescriptor<any>) => {

    const originalMethod = descriptor.value; // Save method

    descriptor.value = async function (...args: any[]): Promise<any> {

        Object.assign(this, { coLoading: true });

        const data = await originalMethod.apply(this, args);

        Object.assign(this, { coLoading: false });

        return data;
    };

    return descriptor;
}

export function getLanguage(code: 'en' | 'nl' | 'fr' | string) {
    switch (code) {
        case 'en': return 3;
        case 'fr': return 2;
        case 'en': return 1;
        default: return 1;
    }
} 