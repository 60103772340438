import Vue from 'vue';
import App from './App.vue';
import router from './_router';
import store from './_store';
import i18n from './i18n';
import BootstrapVue from 'bootstrap-vue';
import '@/_plugin/axios';
import '@/_plugin/element-ui/element';
import '@/_plugin/vue-cal';
import '@/_plugin/vee-validate';
import '@/_plugin/perfect-scrollbar-directive';
import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

window.addEventListener("unhandledrejection", function (event) {
  console.warn("WARNING: Unhandled promise rejection. Reason: " + event.reason, event);
});
