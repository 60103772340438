import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { IRootState } from './types';
import { rootActions } from './_root-actions';
import { rootGetters } from './_root-getters';
import { rootMutations } from './_root-mutations';
import { appointment } from './appointment';
Vue.use(Vuex);

const store = new Store<IRootState>({
  strict: true,
  state: {
    version: '1.0.0',
  },
  getters: rootGetters,
  actions: rootActions,
  mutations: rootMutations,
  modules: {
    appointment
  }
});

export default store;
