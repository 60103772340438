import { ActionTree } from "vuex";
import { appointmentService } from "@/_services";
import { IRootState, IModuleAppointmentState } from "../types";
import { getBoolean } from "@/_utils";
import { IAppointment } from "@/model/appointment.model";

export const actions: ActionTree<IModuleAppointmentState, IRootState> = {
  async getAppointmentByObjectId({ commit }, criteria) {
    commit("fetchAppointments");
    const { objectId, key, IsProject } = criteria;
    return appointmentService
      .getAppoinmentsByObjectID({ objectId, key, IsProject })
      .then((res) => {
        commit("fetchAppointmentsSuccess", res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async getProperty({ state, commit }, criteria) {
    if (state.property) {
      return Promise.resolve();
    }
    commit("fetchProperty");
    const { objectId, key } = criteria;
    return appointmentService
      .getProperty({ objectId, key })
      .then((res) => {
        commit("fetchPropertySuccess", res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  async getProject({ state, commit }, criteria) {
    if (state.project) {
      return Promise.resolve();
    }
    commit("fetchProject");
    const { objectId, key } = criteria;
    return appointmentService
      .getProject({ objectId, key })
      .then((res) => {
        commit("fetchProjectSuccess", res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  syncAppointmentClick({ commit }, criteria) {
    commit("syncAppointmentClick", criteria);
  },

  sendContactAppointment({ commit }, payload) {
    commit("sendContact");
    const { objectId, key, data, email, noEmail } = payload;
    return new Promise(function (resolve, reject) {
      appointmentService
        .sendContactAppointment({ objectId, key, data, email, noEmail })
        .then((res) => {
          commit("sendContactSuccess", res);
          resolve("Send Contact Success !!!");
        })
        .catch((err) => {
          commit("sendContactSuccess", err);
          reject("Send Contact fail !!!");
        });
    });
  },

  storeConfig({ commit }, payload) {
    commit("storeConfig", payload);
  },

  storeError({ commit }, payload) {
    commit("storeError", payload);
  },

  checkAppointmentExists({ state }, payload: number) {
    return appointmentService
      .getAppoinmentsByObjectID({
        objectId: state.config.objectId,
        key: state.config.key,
        IsProject: getBoolean(state.config.isProject),
      })
      .then((res) => {
        const appointment = res.data.GetAppointmentObjectJsonResult.find(
          (a: IAppointment) => a.AppointmentID === payload
        );
        if (res.data.GetAppointmentObjectJsonResult.length === 0) {
          return Promise.resolve(false);
        }
        if (!!appointment) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  checkIfUserRegistered({ state }, criteria: any) {
    return appointmentService
      .getCalendarHistories({
        objectId: state.config.objectId,
        key: state.config.key,
      })
      .then((res) => {
        const user = res.data.GetCalendarHistoriesJsonResult.Value.Items.find(
          (a: any) => a.ContactEmail === criteria.email
        );
        if (user) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};
