import Vue from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

export default class PerfectScrollBarDirective {
    inserted(el: any) {
        const disbledAttr = el.getAttribute('perfect-scrollbar-disabled');
        const hasFixHeightAttr = el.hasAttribute('perfect-scrollbar-fixheight');
        const hasModalAttr = el.hasAttribute('perfect-scrollbar-modal');

        if (disbledAttr === 'true') {
            return;
        }

        if (hasFixHeightAttr) {
            el.style.maxHeight = el.getAttribute('perfect-scrollbar-fixheight') + 'px';
        }

        if (hasModalAttr) {
            const offsetAttr = el.getAttribute('perfect-scrollbar-offset');
            const height = window.innerHeight - 100;
            const offset = offsetAttr ? parseFloat(offsetAttr) : 0;

            el.style.maxHeight = (height - offset - 20) + 'px';
        }

        el.style.position = 'relative';

        el.perfectScrollBarInstance = new PerfectScrollbar(el);
    }

    unbind(el: any) {
        if (el.perfectScrollBarInstance) {
            el.perfectScrollBarInstance.destroy();
            el.perfectScrollBarInstance = null;
        }
    }
}

Vue.directive('perfect-scrollbar', new PerfectScrollBarDirective());
