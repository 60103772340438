import Vue from 'vue';
import {
    ValidationObserver,
    ValidationProvider,
    extend,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";

extend('belgiumPhone', {
    validate(value, args) {
        const phoneNumber1 = new RegExp(/^((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$/g);
        const phoneNumber2 = new RegExp(/^((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/g);
        const phoneNumber3 = new RegExp(/^((\+|00)33\s?|0)[1-59](\s?\d{2}){4}$/g);
        const phoneNumber4 = new RegExp(/^((\+|00)33\s?|0)[67](\s?\d{2}){4}$/g);
        const phoneNumber5 = new RegExp(/^((\+31)|(0031)|0)((0)|)(\d{1,3})(\s|-|)(\d{8}|\d{4}\s\d{4}|\d{2}\s\d{2}\s\d{2}\s\d{2})$/g);
        const phoneNumber6 = new RegExp(/^(([+]|00)39)?(\d{3})(\d{7})$/g);

        return phoneNumber1.test(value) || phoneNumber2.test(value) || phoneNumber3.test(value) || phoneNumber4.test(value) || phoneNumber5.test(value) || phoneNumber6.test(value);
    },
    params: []
});

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule: any) => {
    // @ts-ignore
    extend(rule, rules[rule]);
});

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
