import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const initRoute = function (config: { [prop: string]: any }, url: string) {
    return {
        path: config.path,
        name: config.name,
        children: config.children,
        component: function () { return import(`@/views/${url}.vue`) }
    };
};

const router = new Router({
    mode: 'history',
    routes: [
        initRoute({
            path: '/', name: 'default',
        }, 'home/Home'),
        initRoute({
            path: '/open-appointment', name: 'open-appointment'
        }, 'modules/OpenAppointment/OpenAppointment'),
        initRoute({
            path: '/contact-appointment', name: 'contact-appointment'
        }, 'modules/ContactAppointment/ContactAppointment'),
        initRoute({
            path: '/contact-appointment-project', name: 'contact-appointment-project'
        }, 'modules/ContactAppointmentProject/ContactAppointmentProject'),
        initRoute({
            path: '/confirm-appointment', name: 'confirm-appointment'
        }, 'modules/ConfirmAppointment/ConfirmAppointment')
    ],
});

router.beforeEach(function (to, from, next) {
    next();
});

export default router;
